const images: { [key: string]: string } = {
	Logo: require("./logo.png"),
	UserImg: require("./user-img.png"),
	Bell: require("./ic-bell.svg").default,
	DashboardActive: require("./ic-dashboard-active.svg").default,
	Dashboard: require("./ic-dashboard.svg").default,
	DownArrow: require("./ic-down-arrow.svg").default,
	Flag: require("./ic-flag.svg").default,
	Hamburger: require("./ic-hamburger.svg").default,
	JobMatching: require("./ic-job-matching.svg").default,
	JobsActive: require("./ic-jobs-active.svg").default,
	Jobs: require("./ic-jobs.svg").default,
	MessageActive: require("./ic-message-active.svg").default,
	Message: require("./ic-message.svg").default,
	MyProfile: require("./ic-my-profile.svg").default,
	MyProfile2: require("./ic-my-profile2.svg").default,
	MyProfileActive: require("./ic-my-profile-active.svg").default,
	MyCompany: require("./ic-my-company.svg").default,
	MyCompanyActive: require("./ic-my-company-active.svg").default,
	NewChats: require("./ic-new-chats.svg").default,
	NewJobs: require("./ic-new-jobs.svg").default,
	ProfileIncomplete: require("./ic-profile-incomplete.svg").default,
	Search: require("./ic-search.svg").default,
	AbstractCircle: require("./abstract-circle.svg").default,
	AbstractDots: require("./abstract-dots.svg").default,
	RightArrow: require("./ic-right-arrow.svg").default,
	Setting: require("./ic-setting.svg").default,
	Logout: require("./ic-logout.svg").default,
	MobileLogo: require("./mobile-logo.jpg"),
	Illustration: require("./illustration.png"),
	Talent: require("./talent.png"),
	Professional: require("./professional.png"),
	Company: require("./company.png"),
	Gmail: require("./ic-gmail.svg").default,
	Google: require("./ic-google.svg").default,
	Linkedin: require("./ic-linkedin.svg").default,
	Ellipse1: require("./ellipse-1.png"),
	Ellipse2: require("./ellipse-2.png"),
	ShowPassword: require("./ic-show-password.svg").default,
	HidePassword: require("./ic-hide-password.svg").default,
	DeutschLangLogo: require("./germany-svgrepo-com.svg").default,
	Bot: require("./bot.png"),
	Close: require("./ic-close.svg").default,
	ExpandArrow: require("./ic-expand-arrow.svg").default,
	Attach: require("./ic-attach.svg").default,
	Send: require("./ic-send.svg").default,
	UserImgLarge: require("./user-img-large.png"),
	Facebook: require("./ic-facebook.svg").default,
	Linkedin2: require("./ic-linkedin-2.svg").default,
	Location: require("./ic-location.svg").default,
	Edit: require("./ic-edit.svg").default,
	Chat: require("./ic-chat.svg").default,
	Upload: require("./ic-upload.svg").default,
	CloseFill: require("./ic-close-fill.svg").default,
	Globe: require("./ic-globe.svg").default,
	Badge: require("./ic-badge.svg").default,
	JobTick: require("./ic-job-tick.svg").default,
	Money: require("./ic-money.svg").default,
	Calendar: require("./ic-calendar.svg").default,
	Email: require("./ic-email.svg").default,
	Call: require("./ic-call.svg").default,
	LinkedinPrimary: require("./ic-linkedin-primary.svg").default,
	Education: require("./ic-education.svg").default,
	JobsWhite: require("./ic-jobs-white.svg").default,
	Thumbnail: require("./thumbnail.png"),
	Xing: require("./ic-xing.svg").default,
	CloseLarge: require("./ic-close-large.svg").default,
	DeleteUser: require("./ic-delete-user.svg").default,
	ChangePassword: require("./ic-change-password.svg").default,
	ExpandArrowThick: require("./ic-expand-arrow-thick.svg").default,
	SearchLarge: require("./ic-search-large.svg").default,
	Filter: require("./ic-filter.svg").default,
	LocationGrey: require("./ic-location-grey.svg").default,
	Bookmark: require("./ic-bookmark.svg").default,
	BookmarkActive: require("./ic-bookmark-active.svg").default,
	Clock: require("./ic-clock.svg").default,
	Coin: require("./ic-coin.svg").default,
	JobChLogo: require("./JobsCHLogo.png"),
	Download: require("./ic-download.svg").default,
	LanguageSkill: require("./ic-language-skill.svg").default,
	LanguageSkillWhite: require("./ic-language-skill-white.svg").default,
	Skill: require("./ic-skills.svg").default,
	SkillWhite: require("./ic-skills-white.svg").default,
	Certificate: require("./ic-certificate.svg").default,
	AlphaLogo: require("./AlphaLogo.png"),
	IctcareerChLogo: require("./ictcareer-ch.png"),
	IngjobsChLogo: require("./ingjobs-ch.png"),
	Jobs4financeChLogo: require("./jobs4finance-ch.png"),
	Jobs4salesChLogo: require("./jobs4sales-ch.png"),
	Jobscout24Logo: require("./jobscout24Logo.png"),
	JobupLogo: require("./jobup-logo-1.png"),
	MedtalentsChLogo: require("./medtalents-ch.png"),
	TopjobsChLogo: require("./topjobs-ch.png"),
	JobcloudLogo: require("./jobcloud-logo.png"),
	JobWinnerLogo: require("./jobwinner.png"),
	TrashCan: require("./ic-trash-can.svg").default,
	TrashCanGrey: require("./ic-trash-canGrey.svg").default,
	CertificateWhite: require("./ic-certificate-white.svg").default,
	MyCV: require("./ic-myCv.svg").default,
	MyCVActive: require("./ic-myCv-active.svg").default,
	EducationGreen: require("./ic-education-green.svg").default,
	Division: require("./ic-division.svg").default,
	Star: require("./ic-star.svg").default,
	StarActive: require("./ic-star-active.svg").default,
	Staff: require("./ic-staff.svg").default,
	StaffActive: require("./ic-staff-active.svg").default,
	ChatWhite: require("./ic-chat-white.svg").default,
	Information: require("./ic-info.svg").default,
	InformationActive: require("./ic-info-active.svg").default,
	JobTickWhite: require("./ic-job-tick-white.svg").default,
	MyCVWhite: require("./ic-myCv-white.svg").default,
	ResidencePermit: require("./ic-residence-permit.svg").default,
	ClockActive: require("./ic-clock-active.svg").default,
	Video: require("./ic-video.svg").default,
	Trash: require("./ic-trash.svg").default,
	DefaultProfilePhoto: require("./default-profile-photo.png"),
	UserHeaderDefault: require("./user-header.png"),
	CompanyHeaderDefault: require("./klinik.png"),
	CompanyLogoDefault: require("./hospital.png"),
	YouTube: require("./ic-youtube.svg").default,
	PlayIcon: require("./ic-play.svg").default,
};

export default images;
